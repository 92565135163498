import React from 'react'
import {
  Nav,
  NavContainer,
  NavLogo,
  NavMenu,
  NavItem,
  NavLinks,
} from './NavbarElements'
import logo from '../../images/logo.png'

const Navbar = () => {
  return (
    <>
      <Nav>
        <NavContainer>
          <NavLogo>
            <img src={logo} alt='' />
          </NavLogo>
          {/* <MobileIcon>
            <Fabar />
          </MobileIcon> */}
          <NavMenu>
            <NavItem>
              <NavLinks to='/'>Home</NavLinks>
              <NavLinks href='http://mb.yamatodao.io/index.html#'>
                Contact
              </NavLinks>
              <NavLinks href='http://mb.yamatodao.io/signin.aspx'>Login</NavLinks>
            </NavItem>
          </NavMenu>
        </NavContainer>
      </Nav>
    </>
  )
}

export default Navbar
