import React from 'react'
import footer_bg from '../../images/footer-bg.png'
import footer_logo from '../../images/YamatodaoLogo.png'
import {
  FooterSec,
  FooterImg,
  FooterNav,
  FooterNavLogo,
  FooterNavItems,
  FooterNavLinks,
  FooterCompany,
  FooterContainer,
} from './FooterElements'

const Footer = () => {
  return (
    <>
      <FooterSec>
        <FooterImg>
          <img className='bgImg_footer' src={footer_bg} alt='' />
        </FooterImg>
        <FooterContainer>
          <FooterNav>
            <FooterNavLogo>
              <img src={footer_logo} alt='' />
            </FooterNavLogo>
            <FooterNavItems>
              <FooterNavLinks>Home</FooterNavLinks>
              <FooterNavLinks>Contact</FooterNavLinks>
              <FooterNavLinks>Login</FooterNavLinks>
            </FooterNavItems>
          </FooterNav>
          <FooterCompany>©︎ All right in YAMATO.DAO</FooterCompany>
        </FooterContainer>
      </FooterSec>
    </>
  )
}

export default Footer
