import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const FooterSec = styled.section`
  height: 33.3vw;
  position: relative;
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 440px) {
    height: 128vw;
  }
`

export const FooterImg = styled.div`
  position: absolute;
  width: 100%;
  height: 33.3vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 440px) {
    height: 128vw;
    z-index: -1;
  }
  .bgImg_footer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
`
export const FooterContainer = styled.div`
  max-width: 83.33vw;
  height: 33.3vw;
  margin-left: auto;
  margin-right: auto;
  position: relative;

`

export const FooterNav = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  margin-top: 12.986vw;
  width: 83.33vw;
  border-top: 1px solid #ffffff;
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 440px) {
    margin-top: 49.986vw;
    width: 100%;
  }
`

export const FooterNavLogo = styled.div`
  width: 13.88vw;
  height: 100px;
  color: transparent;
  position: relative;
  &:after {
    content: '';
    width: 1px;
    height: 2.22vw;
    background-color: #ffffff;
    position: absolute;
    display: inline-block;
    right: 0;
    top: 30%;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 440px) {
    display: none;
  }
`

export const FooterNavItems = styled.div`
  margin-left: 32px;
  position: relative;
  height: 100px;
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 440px) {
    display: flex;
    margin: 0 auto;

    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const FooterNavLinks = styled(LinkR)`
  position: relative;
  top: 30%;
  margin-right: 64px;
  font-size: 1.25vw;
  letter-spacing: 0.08em;
  color: #ffffff;
  font-weight: 300;
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 440px) {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-size: 4.25vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const FooterCompany = styled.p`
  position: absolute;
  bottom: 6.66vw;
  font-size: 1.25vw;
  letter-spacing: 0.08em;
  color: #ffffff;
  font-weight: 300;
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 440px) {
    bottom: -80.66vw;
    left: 18vw;
    font-size: 3.25vw;
  }
`
