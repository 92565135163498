import './App.css';
import Navbar from './components/Navbar';
import Fv from './components/Fv';
import DaoContents from './components/DaoContents';
import Philosophy from './components/Philosophy'; 
import Footer from './components/Footer';
import {BrowserRouter as Router} from 'react-router-dom';


function App() {
  return (
    <Router>

      <Navbar />
      <Fv />
      <DaoContents />
      <Philosophy />
      <Footer />
    </Router>
  )
}

export default App;
