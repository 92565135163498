import styled from 'styled-components'

export const DaoContentsSec = styled.section`
  height: 75vw;
  position: relative;
  @media screen and (max-width: 768px) {
    height: 140.625vw;
  }
  @media screen and (max-width: 440px) {
    height: 288vw;
  }
  .lineGradi {
    position: absolute;
    width: 100%;
    top: -26%;
    z-index: 2;
    @media screen and (max-width: 768px) {
      top: -14%;
    }
    @media screen and (max-width: 440px) {
      top: -7%;
    }
  }
`
export const DaoContentsbg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #011f45;
`
export const DaoContent = styled.div`
  top: 12.7vw;
  max-width: 83.33vw;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @media screen and (max-width: 768px) {
    top: 24.73vw;
  }
`
export const DaoContentTtl = styled.h3`
  font-size: 2.5vw;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 2px;
  @media screen and (max-width: 768px) {
    font-size: 4.687vw;
  }
  @media screen and (max-width: 440px) {
    font-size: 8.5vw;
  }
`
export const DaoContentCard = styled.div`
  max-width: 69.44vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  flex-flow: wrap;

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    flex-flow: wrap;
  }
`
export const CardItem = styled.div`
  width: 19.44vw;
  border: 1px solid #ffffff;
  border-radius: 10px;
  height: 27.77vw;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 7.15vw;
  @media screen and (max-width: 768px) {
    height: 19.531vw;
    width: 39.06vw;
  }
  @media screen and (max-width: 440px) {
    height: 34.666vw;
    width: 66.66vw;
    margin-top: 16.15vw;
  }

  p {
    color: #ffffff;
    font-size: 1.25vw;
    letter-spacing: 0.08em;
    font-weight: bold;
    height: 2.569vw;
    position: relative;
    @media screen and (max-width: 768px) {
      font-size: 2.34vw;
    }
    @media screen and (max-width: 440px) {
      font-size: 4.26vw;
    }
    &:after {
      content: '';
      width: 4.09vw;
      height: 1px;
      margin-top: 1.32vw;
      display: block;
      position: absolute;
      left: calc(50% - 2.45vw);
      background-color: #ffffff;
      @media screen and (max-width: 768px) {
        width: 7.682vw;
        left: calc(50% - 3.841vw);
      }
      @media screen and (max-width: 440px) {
        width: 15.73vw;
        left: calc(50% - 7.865vw);
        margin-top: 0.32vw;
      }
    }
  }
`
export const DaoContentText = styled.p`
  max-width: 69.44vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7.08vw;
  font-size: 0.83vw;
  color: #ffffff;
  letter-spacing: 0.08em;
  font-family: operetta-8, serif;
  font-weight: 300;
  @media screen and (max-width: 768px) {
    font-size: 1.56vw;
    line-height: 12px;
    margin-left: 120px;
  }
  @media screen and (max-width: 440px) {
    font-size: 3.2vw;
    line-height: 18px;
    margin-left: 30px;
    margin-top: 100px;
  }
`
