import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const Nav = styled.nav`
  background: transparent;
  z-index: 10;
`

export const NavContainer = styled.div`
  max-width: 83.3vw;
  margin: 0 auto;
  position: relative;
  border-bottom: 0.5px solid #ffffff;
`

export const NavLogo = styled.div`
  padding-top: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  img {
    width: 100%;
  }
`
export const NavMenu = styled.ul`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavItem = styled.li`
  height: 80px;
  padding-top: 35px;
`

export const NavLinks = styled.a`
  font-size: 18px;
  color: #ffffff;
  margin-right: 50px;
  font-family: operetta-8, serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 2px;
`
