import styled from 'styled-components'

export const FvSection = styled.section`

`

export const FvParallax = styled.section`
  position: relative;
  width: 100%;
  top: -80px;
  height: 87.25vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  z-index: -1;
  @media screen and (max-width: 768px) {
    height: 163.6vw;
  }
  @media screen and (max-width: 440px) {
    height: 334.9vw;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
  .ttl {
    position: relative;
    color: #fff;
    font-size: 6.9vw;
    z-index: 0;
    top: 250px;
    font-weight: bold;
    @media screen and (max-width: 768px) {
      font-size: 9.37vw;
    }
    @media screen and (max-width: 440px) {
      font-size: 10.666vw;
    }
  }

  .bgImg_front {
    z-index: 1;
  }
`
export const Fvtext = styled.p`
  max-width: 69.4vw;
  margin: 0 auto;
  position: relative;
  top: -27vw;
  color: #ffffff;
  font-size: 0.83vw;
  letter-spacing: 0.08em;
  font-family: operetta-8, serif;
  font-weight: 300;
  @media screen and (max-width: 768px) {
    font-size: 1.56vw;
    max-width: 69.79vw;
    top: -58vw;
  }
  @media screen and (max-width: 440px) {
    font-size: 3.2vw;
    max-width: 47.2vw;
    top: -120vw;
    left: -20vw;
  }
`

export const FvCv = styled.div`
  z-index: 10;
  position: absolute;
  top: 90vh;
  left: 0;
  right: 0;
  text-align: center;
  @media screen and (max-width: 768px) {
    top: 50vh;
  }

  a {
    width: 19.9vw;
    height: 3.4vw;
    color: #ffffff;
    display: inline-block;
    border: 1px solid #ffffff;
    font-size: 1.66vw;
    font-weight: 300;
    transition: background-color 0.5s;
    @media screen and (max-width: 440px) {
      width: 76.53vw;
      height: 13.06vw;
      font-size: 6.4vw;
    }

    &:hover {
      background-color: #707070;
    }
  }
`
