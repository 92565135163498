import styled from 'styled-components'

export const PhilosophySec = styled.section`
  height: 45.6vw;
  min-height: 550px;
  background-color: #ffffff;
  @media screen and (max-width: 768px) {
    height: 81.7vw;
    min-height: 628px;
  }
  @media screen and (max-width: 440px) {
    height: 167.46vw;
    min-height: 628px;
  }
`
export const PhilosophySecContainer = styled.div`
  max-width: 83.33vw;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 7vw;
  @media screen and (max-width: 768px) {
    top: 13vw;
  }
  @media screen and (max-width: 440px) {
    top: 23vw;
  }
`

export const PhilosophyTtl = styled.h3`
  font-size: 2.5vw;
  font-weight: bold;
  color: #343434;
  letter-spacing: 2px;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 768px) {
    font-size: 4.68vw;
  }
  @media screen and (max-width: 440px) {
    font-size: 8.55vw;
  }
`

export const PhilosophyBg = styled.div`
  position: relative;
  width: 69.44vw;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 69.44vw;
  }
  @media screen and (max-width: 440px) {
    width: 69.44vw;
  }
`

export const PhilosophyBg_bg = styled.div`
  width: 69.44vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -30px;
  @media screen and (max-width: 768px) {
    width: 69.44vw;
  }
  @media screen and (max-width: 440px) {
    display: none;
  }
  img {
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
`
export const PhilosophyBg_main = styled.div`
  width: 69.44vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 6vw;
  @media screen and (max-width: 440px) {
    top: 20vw;
  }

  img {
    z-index: 0;
    top: 0;
    left: 0;
    width: 27.77vw;
    max-width: 400px;
    object-fit: cover;
    pointer-events: none;
    @media screen and (max-width: 768px) {
    }
    @media screen and (max-width: 440px) {
      width: 50.77vw;
    }
  }
`
export const PhilosophyBg_bg_sp = styled.div`
  display: none;
  @media screen and (max-width: 440px) {
    width: 120%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: 6vw;
    left: -9vw;
    img {
      z-index: 0;
      top: 0;
      left: 0;
      width: 160.77vw;
      object-fit: cover;
      pointer-events: none;
    }
  }
`

export const PhilosophyTxt = styled.p`
  width: 35.55vw;
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 15vw;
  font-size: 1.11vw;
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 440px) {
    width: 80vw;
    z-index: 2;
    top: 35vw;
    font-size: 4.26vw;
  }
`
export const PhilosophyCv = styled.div`
  position: relative;
  width: 19.93vw;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  max-width: 287px;
  top: 18vw;
  border: 1px solid #343434;
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 440px) {
    width: 76.53vw;
    max-width: 287px;
    top: 48vw;
  }
  a {
    display: inline-block;
    padding: 10px 0;
    width: 19.93vw;
    max-width: 287px;
    font-size: max(1.66vw, 24px);
    letter-spacing: 0.08em;
    color: #343434;
    @media screen and (max-width: 768px) {
    }
    @media screen and (max-width: 440px) {
      width: 76.53vw;
      max-width: 287px;
      top: 48vw;
      padding: 5px 0;
    }
  }
`



export const PhilosophyTxtBtm = styled.p`
  position: absolute;
  top: 32vw;
  width: 100%;
  font-size: 12px;
  text-align: right;
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 440px) {
    top: 130vw;
    z-index: 10;
  }
`
