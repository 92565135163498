import React from 'react'
import {
  PhilosophySec,
  PhilosophySecContainer,
  PhilosophyTtl,
  PhilosophyBg,
  PhilosophyBg_bg,
  PhilosophyBg_main,
  PhilosophyTxt,
  PhilosophyCv,
  PhilosophyTxtBtm,
  PhilosophyBg_bg_sp,
} from './PhilosophyElements'
import bg_img from '../../images/philosophybg.png'
import main_img from '../../images/philosophy_main.png'
import sp_philosophy from '../../images/sp-philosophy.png'


const Philosophy = () => {
  return (
    <>
      <PhilosophySec>
        <PhilosophySecContainer>
          <PhilosophyTtl>Philosophy</PhilosophyTtl>
          <PhilosophyBg>
            <PhilosophyBg_bg>
              <img src={bg_img} alt='' />
            </PhilosophyBg_bg>
            <PhilosophyBg_bg_sp>
              <img src={sp_philosophy} alt='' />
            </PhilosophyBg_bg_sp>
            <PhilosophyBg_main>
              <img src={main_img} alt='' />
            </PhilosophyBg_main>
          </PhilosophyBg>
          <PhilosophyTxt>
            会員が相互に知識を持ちより
            <br />
            より豊かなライフスタイルを実現するためのコミュニティー
          </PhilosophyTxt>
          <PhilosophyCv>
            <a href='http://mb.yamatodao.io/signin.aspx'>Login Here</a>
          </PhilosophyCv>
          <PhilosophyTxtBtm>
            members to have mutual knowledge
            <br />A community for realizing a richer lifestyle
          </PhilosophyTxtBtm>
        </PhilosophySecContainer>
      </PhilosophySec>
    </>
  )
}

export default Philosophy
