import React, { useEffect } from 'react'
import { FvSection, FvParallax, Fvtext, FvCv } from './FvElements'
import top_bg from '../../images/top-bg.png'
import top_front from '../../images/top-front.png'

const Fv = () => {
  useEffect(() => {
    let fvcv = document.getElementById('fvcv')
    let ttl = document.getElementById('ttl')
    window.addEventListener('scroll', function () {
      var value = this.window.scrollY
      var width = this.window.innerWidth 
      console.log(value ,width)
        ttl.style.top = 250 + value * 0.8 + 'px'
        // fvcv.style.top = 90 + value * 0.035 + 'vh'
    })
  }, [])

  return (
    <>
      <FvSection>
        <FvParallax>
          <img
            className='bgImg_front'
            src={top_front}
            alt=''
            id='bgImg-front'
          />
          <img className='bgImg' src={top_bg} alt='' id='bgImg' />
          <h2 className='ttl' id='ttl'>
            YAMATO.DAO
          </h2>
        </FvParallax>
        <Fvtext>
          YAMATO.DAO is a co-creative community that learns about economics and
          <br />
          technology. Help each other by proactively addressing future crises
          <br />
          that may arise as technology evolves. That’s what our community is for
        </Fvtext>
        <FvCv id='fvcv'>
          <a href='http://mb.yamatodao.io/signin.aspx'>Login Here</a>
        </FvCv>
      </FvSection>
    </>
  )
}

export default Fv
