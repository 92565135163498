import React from 'react'
import {
  DaoContentsSec,
  DaoContentsbg,
  DaoContent,
  VideoBg,
  DaoContentTtl,
  DaoContentCard,
  CardItem,
  DaoContentText,
} from './DaoContentsElements'
import Video from '../../videos/bg.mp4'
import LinedradientImg from '../../images/linegradient_video.png'
import { useInView } from 'react-intersection-observer'
import 'animate.css'
import './cardamimation.css'


const DaoContents = () => {
  const { ref, inView } = useInView({
    // オプション
    rootMargin: '50px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <>
      <DaoContentsSec>
        <img src={LinedradientImg} alt='' className='lineGradi' />
        <DaoContentsbg>
          <VideoBg autoPlay loop muted src={Video} type='video/mp4'></VideoBg>
        </DaoContentsbg>
        <DaoContent>
          <DaoContentTtl>DAO Contents</DaoContentTtl>
          <DaoContentCard>
            <CardItem id='card1' className={inView && 'up_card'}>
              <p>FINTEC Community</p>
            </CardItem>
            <CardItem>
              <p>CryptCoin & WALLET</p>
            </CardItem>
            <CardItem id='card2' className={inView && 'down_card'}>
              <p>NFT & Metaverse</p>
            </CardItem>
          </DaoContentCard>
          <DaoContentText ref={ref}>
            At YAMATO DAO., we are learning information and services that will
            <br />
            be beneficial to our members. Information on Financial Technology
            <br />
            and financial services using Blockchain technology We provide
            <br />
            excellent insiders, capital gains information, decentralization
            <br />
            related services and products, etc.
          </DaoContentText>
        </DaoContent>
      </DaoContentsSec>
    </>
  )
}

export default DaoContents
